import { mapHelper } from "@/utils/utils.js";

// 	状态
const status = [
  {
    value: 0,
    text: "未出租",
  },
  {
    value: 1,
    text: "已出租",
  },
  {
    value: 2,
    text: "待审核",
  },
];

const { map: statusMap, setOps: setstatusOps } = mapHelper.setMap(status);

// 	订单状态
const orderStatus = [
  {
    value: 0,
    text: "待审核",
  },
  {
    value: 1,
    text: "已生效",
  },
  {
    value: 2,
    text: "已驳回",
  },
  {
    value: 3,
    text: "已取消",
  },
];

const { map: orderStatusMap, setOps: setOrderStatusOps } =
  mapHelper.setMap(orderStatus);

// 	按钮状态
const btnStatus = [
  {
    value: 0,
    text: "立即预订",
  },
  {
    value: 1,
    text: "查看",
  },
  {
    value: 2,
    text: "查看",
  },
];

const { map: btnStatusMap, setOps: setBtnStatusOps } =
  mapHelper.setMap(btnStatus);

export {
  statusMap,
  setstatusOps,
  btnStatusMap,
  setBtnStatusOps,
  orderStatusMap,
  setOrderStatusOps,
};
